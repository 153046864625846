import React from "react"
import Grid from './components/Grid';

const App=()=> {
  return (
    <div className="">
      <Grid/>
    </div>
  );
}

export default App;
