export default [
    {
        id: 1,
        title:"House"
    },
    {
        id:2,
        title:"Office"
    },
    {
        id:3,
        title:"Gym"
    },
    {
        id:4,
        title:"Restaurant"
    },
    {
        id:5,
        title:"Hospital"
    },
    {
        id:6,
        title:"Store"
    }
]